// src/routes.js
import { createRouter, createWebHistory } from 'vue-router';
import loadingService from '@/services/loadingService';


// Importez les composants associés à chaque route
import Home from './views/Home.vue';
import Blog from './views/Blog.vue';
import Forumplus from './views/Forumplus.vue';
import Question from './views/Question.vue';
import Section from './views/Section.vue';
import Motivation from './views/Motivation.vue';
import Formation from './views/Formation.vue';
import SalleReseau from './views/SalleReseau.vue';
import Video from './views/Video.vue';
import Propos from './views/Propos.vue';
import SalleDefence from './views/SalleDefence.vue';
import LoginForm from './views/LoginForm.vue';
import SendMail from './views/SendMail.vue';
import ResetPassword from './views/ResetPassword.vue';
import Conditions from './views/Conditions.vue';
import conditionForum from './views/conditionForum.vue';
import Contact from './views/Contact.vue';
import Quiz from './views/Quiz.vue';
import RegisterFrom from './views/RegisterFrom.vue';
import Profils from './views/Profils.vue';
import BlogDetail from './views/BlogDetail.vue';
import LoginLayout from './layouts/LoginLayout.vue';
import ResetLayout from './layouts/ResetLayout.vue';
import RegisterLayout from './layouts/RegisterLayout.vue';


const routes = [
  { path: '/', component: Home },
  { path: '/blog', component: Blog },
  { path: '/challenge', component: Quiz },
  { path: '/contact', component: Contact },
  { path: '/motivation', component: Motivation },
  { path: '/communaute', component: SalleReseau },
  { path: '/video', component: Video },
  { path: '/blogplus/:id', component: BlogDetail },
  { path: '/salledefence', component: SalleDefence },
  { path: '/condition', component: Conditions },
  { path: '/conditionForum', component: conditionForum },
  { path: '/propos', component: Propos },
  { path: '/forumplus/:id', component: Forumplus },
  { path: '/section/:id', component: Section },
  { path: '/question', component: Question },
  { path: '/formation', component: Formation },
  {
    path: "/profils",
    component: Profils
  },
  {
    path: '/auth/login',
    component: LoginForm,
    meta: { layout: LoginLayout, public: false },
  },
  {
    path: '/auth/password',
    component: SendMail,
    meta: { layout: LoginLayout, public: false },
  },
  { path: '/auth/password/reset', component: ResetPassword, 
  meta: { layout: ResetLayout, public: false },
},
  {
    path: '/auth/register',
    component: RegisterFrom,
    meta: { layout: RegisterLayout, public: false }
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  router.beforeEach((to, from, next) => {
    
    loadingService.setLoading(true);
    setTimeout(() => {
      loadingService.setLoading(false);
    }, 1000);
    next();
  });

export default router;