<template>
  <div class="mt-9 lg:mt-0 custom-background min-h-screen">
    <div class="h-9"></div>

    <form
      v-if="quizStatus === 'question'"
      action="#"
      method="post"
      @submit.prevent="sendResult()"
      class="mt-9 lg:ml-6"
    >
      <p class="lg:text-xl text-lg font-serif text-green-300 text-center">
        Le challenge a été publié le
        {{
          new Date(dateHeure).toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        }}. Les réponses seront disponibles 72 heures plus tard.
      </p>
      <h3 class="lg:text-3xl text-lg font-mono text-white text-center mt-4">
        {{ theme }}
      </h3>

      <div class="h-16"></div>

      <div
        v-for="(question, questionIndex) in sortedQuiz"
        :key="questionIndex"
        class="ml-9 lg:ml-0"
      >
        <h3 class="lg:text-xl text-lg font-serif text-white">
          {{ question.title }}
        </h3>
        <div class="h-9"></div>
        <div class="ml-4 mb-6">
          <div
            v-for="(answer, answerIndex) in question.answers"
            :key="answerIndex"
            class="flex mt-4"
          >
            <input
              type="radio"
              :name="'question' + questionIndex"
              :value="answer.id"
              v-model="reponseQuiz[answer.id]"
              @change="selecteAnswers(question.id, answer.id)"
              class="h-9 w-6"
            />
            <label class="font-serif text-white ml-4 text-lg">
              {{ answer.title }}
            </label>
          </div>
        </div>
      </div>

      <div class="button-container">
        <button type="submit" class="button mb-4">
          <div class="blob1"></div>
          <div class="blob2"></div>
          <div class="inner">
            <span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> Envoyer</span>
          </div>
        </button>
      </div>
    </form>
    <form v-else action="/submit" method="post" class="mt-9 lg:ml-6">
      <h3 class="lg:text-3xl text-lg font-mono text-white text-center">
        {{ theme }}
      </h3>

      <div class="h-16"></div>

      <div
        v-for="(question, questionIndex) in sortedQuiz"
        :key="question.id"
        class="ml-9 lg:ml-0"
      >
        <h3 class="lg:text-3xl text-lg font-mono text-white">
          {{ question.title }}
        </h3>
        <div class="h-9"></div>
        <div class="ml-4 mb-6">
          <div
            v-for="(answer, index) in question.answers"
            :key="index"
            class="flex mt-4"
          >
            <input
              type="radio"
              :name="'question-' + questionIndex"
              :value="answer.id"
              class="h-9 w-6"
              :checked="answer.is_correct === 1"
            />
            <label
              :class="[
                'font-serif',
                'ml-4',
                'text-lg',
                {
                  'text-white': answer.is_correct !== 1,
                  'text-green-600': answer.is_correct === 1,
                },
              ]"
            >
              {{ answer.title }}
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "Blog",
  components: {
    spiner,
  },
  data() {
    return {
      quizs: [],
      questions: [],
      user: "",
      theme: "",
      quizID: "",
      quizStatus: "",
      dateHeure: "",
      isLoading: false,
      reponseQuiz: [],
      responsesQ: [],
      addform: {
        user_id: "",
        answers_id:[]
      },
    };
  },
  created() {
    this.profile();
    this.getQuiz();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.sortedBlogs.length / this.itemsPerPage);
    },

    sortedQuiz() {
      // Triez les vidéos par date de création dans l'ordre croissant
      return this.questions
        .slice()
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    },
  },
  methods: {
    selecteAnswers(id, data) {
      const index = this.responsesQ.findIndex((item) => item.id == id);

      if (index !== -1) {
        // ID existe, remplacer l'objet
        this.responsesQ[index] = { id, data };
      } else {
        // ID n'existe pas, ajouter un nouvel objet
        this.responsesQ.push({ id, data });
      }
      console.log(this.responsesQ);
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getQuiz() {
      try {
        const response = await axios.get("/api/quizs");
        if (response.data) {
          this.quizs = response.data.data.filter(
            (quiz) => quiz.status === "question" || quiz.status === "reponse"
          );
          this.theme = this.quizs[0].title;
          this.quizID = this.quizs[0].id;
          this.quizStatus = this.quizs[0].status;
          this.dateHeure = this.quizs[0].updated_at;
          this.getQuestion(this.quizID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getQuestion(id) {
      try {
        const response = await axios.get(`/api/questions?quiz_id=${id}`);
        if (response.data) {
          this.questions = response.data.data;
          console.log(this.questions);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async sendResult() {
     this.responsesQ.forEach(element => {
       this.addform.answers_id.push(element.data);
      
     });
     console.log(this.addform.answers_id);

      try {
        this.addform.user_id = this.user;
        this.isLoading = true;
        if (this.addform.user_id === "") {
          this.$router.push("/auth/login");
          return;
        }
        const response = await axios.post("/api/usersresult", this.addform);
        if (response.status === 201) {
          this.addform = {};
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci d'avoir répondu au challenge.",
            timeout: 5000,
          }).show();
          this.$router.push("/");
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response && error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
  },
};
</script>
<style scoped>
.custom-background {
  background-image: url("~@/assets/CHALENGE.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 16px;
  border: none;
  padding: 2px;
  background: radial-gradient(circle 80px at 80% -10%, #ffffff, #181b1b);
  position: relative;
}
.button::after {
  content: "";
  position: absolute;
  width: 65%;
  height: 60%;
  border-radius: 120px;
  top: 0;
  right: 0;
  box-shadow: 0 0 20px #ffffff38;
  z-index: -1;
}

.blob1 {
  position: absolute;
  width: 70px;
  height: 100%;
  border-radius: 16px;
  bottom: 0;
  left: 0;
  background: radial-gradient(
    circle 60px at 0% 100%,
    #3fe9ff,
    #0000ff80,
    transparent
  );
  box-shadow: -10px 10px 30px #0051ff2d;
}

.inner {
  padding: 14px 25px;
  border-radius: 14px;
  color: #fff;
  z-index: 3;
  position: relative;
  background: radial-gradient(circle 80px at 80% -50%, #777777, #0f1111);
}
.inner::before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 14px;
  background: radial-gradient(
    circle 60px at 0% 100%,
    #00e1ff1a,
    #0000ff11,
    transparent
  );
  position: absolute;
}

/* Ajoutez des styles personnalisés pour le slider ici */
</style>
