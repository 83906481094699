<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center mt-9 lg:mt-0">
      <h1 class="font-bold lg:text-4xl text-xl text-white">
        Règles du forum et conditions d'utilisation
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4 mx-4 lg:mx-0">
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Ce message constitue les conditions particulières d’utilisation du forum
        et complète les Conditions générales d’utilisation du site.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Article 1. Définitions
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        <strong>•</strong> « Membre » : il s'agit de toute personne inscrite sur
        le Forum.<br />
        <strong>•</strong> « Contribution » : également appelé « message » et se
        définissant comme toute intervention d'un Membre pour initier ou
        répondre à une Discussion.<br />
        <strong>•</strong> « Discussion » : échanges écrits sur le Forum à
        l'initiative d'un Membre et/ou du Modérateur.<br />
        <strong>•</strong> « Forum » : il s'agit de l'espace mis à disposition
        sur le Site permettant aux membres d'engager des Discussions sur les
        thèmes des Réseaux Informatiques, Sécurité et Cybersécurité.<br />
        <strong>•</strong> « Modération » : il s'agit du contrôle a posteriori,
        c'est-à-dire après mise en ligne de la Contribution que le Site
        s'autorise à mettre en œuvre.<br />
        <strong>•</strong> « Modérateur » : il s'agit en l'espèce de la même
        personne que l'Exploitant du Site.<br />
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">Article 2. Objet</h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les présentes Conditions Particulières d'Utilisation du Forum ont pour
        objet de préciser les conditions dans lesquelles les Membres peuvent
        s'inscrire, créer ou intervenir dans des Discussions.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Article 3. Modalités d'inscription
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Avant de pouvoir poster le premier message, le Membre doit
        s'inscrire.<br />
        En s'inscrivant, l'adresse email du Membre reste strictement
        confidentielle. Le Membre peut autoriser (ou non) les autres Membres à
        leur envoyer des messages privés en suivant le menu : Confidentialité /
        Démarrer des conversations avec vous. <br />Afin de Protéger l'anonymat
        du Membre, le Site recommande d'utiliser un Identifiant différent de
        l'identité et des pseudos utilisés sur d'autres forums. Le Site
        recommande de ne pas poster de pièces jointes permettant d'identifier le
        Membre (notamment dans les propriétés des documents Word ou Excel).
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Article 4. Contenu des Discussions
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les Discussions sur le forum sont de la seule et unique responsabilité
        du Membre. Les Discussions ne peuvent porter que sur les thèmes des
        Réseaux Informatiques, Sécurité et Cybersécurité.<br />
        Toute Discussion doit répondre aux règles de forme et de fond suivantes
        :<br />
        • Interdiction de poster des Contributions à caractère directement ou
        indirectement diffamatoire, injurieux ou délictuel<br />
        • Interdiction de diffuser les données personnelles d'un Membre ou de
        toute autre personne physique, car ces données font l'objet d'un droit
        de modification ou de suppression. Sont notamment concernés : l'adresse
        email ou postale, le nom de famille, le téléphone<br />
        • Interdiction de poster des Contributions à caractère directement ou
        indirectement commercial, publicitaire, prospectif. Une tolérance peut
        toutefois être appliquée par le Modérateur, au cas par cas, quand il
        s'agit de fournir une information pertinente et indispensable dans le
        contexte d'une conversation.<br />
        • Indiquer un intitulé explicite pour toute nouvelle Discussion, les
        titres tels que 'votre avis', 'besoin de conseil' sont déconseillés car
        trop généraux, le Site se réserve le droit de pourvoir les modifier
        voire les supprimer<br />
        • Pour poser une nouvelle question, le Membre doit ouvrir une nouvelle
        Discussion. Il ne faut pas le faire dans une discussion déjà en cours,
        même si son sujet est très proche<br />
        • Le Membre doit, à tout moment faire preuve de courtoisie et observer
        les règles élémentaires de bonne conduite<br />
        • Il est interdit de formuler nommément des attaques et des reproches
        personnels contre des personnes physiques<br />
        • Le Membre doit s'interdire de publier sur le Forum tout contenu sur
        lequel il ne dispose pas des droits d'auteurs<br />
        • En particulier, sauf autorisation du site concerné, il est interdit de
        recopier le contenu d'une page internet sur le Forum par respect du
        droit d'auteur. En revanche, le Membre peut faire un lien vers la page
        en question, en y ajoutant la source, un résumé ou un court extrait.<br />
        À tout moment un Membre peut signaler la violation de ces règles par le
        lien « signaler » présent sous chaque message, ou, par l'intermédiaire
        du formulaire de contact (ça doit être le lien de notre mail). Certaines
        parties du Forum (sous-forum) peuvent présenter des consignes de
        rédaction spécifiques.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Article 5. Contrôle à posteriori des Discussions
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Le Site se réserve la possibilité de contrôler a posteriori les
        Contributions des Membres afin de préserver la qualité des Discussions
        et le respect des Conditions Générales et Particulières applicables. A
        ce titre, le Membre donne au Site le droit de modifier ou de retirer, de
        plein droit, en tout ou partie les Contributions qui ne seraient pas
        conformes aux Conditions applicables.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Article 6. Suspension – Exclusion d'un Membre
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        La violation par un Membre d'une des règles des présentes Conditions
        Particulières ouvre le droit pour le Site, selon la gravité des faits,
        soit de suspendre l'accès au Site pour un membre durant une durée
        déterminée, soit d'exclure le Membre et donc de résilier son accès aux
        Services, à tout moment et de plein droit.
      </p>

      <div class="lg:flex mt-6">
        <button @click="Accepted()" >
          <div
            class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-green-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
              />
            </svg>

            <p class="ml-2 mt-1 font-sans"><span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> Accepter</span></p>
          </div>
        </button>
        <router-link to="/" class="ml-9">
          <div
            class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-red-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>

            <p class="ml-2 mt-1 font-sans">Refuser</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "App",
  components: {
    spiner,
 
  },
  data() {
    return {
    addform: {
        user_id: "",
      },
      user: "",
      isLoading: false,
    };
  },
   mounted() { 
    this.profile();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
   
    async Accepted() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.user;
        const response = await axios.post("/api/condictions", this.addform);
        if (response.status === 201) {
          this.addform = {};
          
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci d'avoir accepter les condicitons d'utilisation.",
            timeout: 5000,
          }).show();
          this.$router.push("/question");
        }  if (response.status === 302) {
          this.showAlert = true;
            this.isLoading = false;
          this.alert.message = "Veuillez vous connecter, s'il vous plaît.";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  