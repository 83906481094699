import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import "./index.css";
import store from "./store";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import ApiService from "./services/api.service";
import TokenService from "./services/storage.service";
import mixpanel from "mixpanel-browser";

Pusher;

ApiService.init("https://test1.csetrainings.com/");
if (TokenService.getToken()) {
  ApiService.setHeader();
}
mixpanel.init("575d1cf519704fb33bf51e6eee886995", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});
window.Echo = new Echo({
  auth: {
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  },
});

createApp(App)
.use(store)
.use(router)
.mount('#app')
