<template>
  <div v-for="formation in filteredFormations" :key="formation.id" class="">
    <div class="h-24 bg-gray-800 flex items-center justify-center">
      <h1
        class="text-blue-500 lg:text-3xl text-xl mt-4 lg:text-left font-mono md:mx-auto md:text-center"
      >
        {{ formation.name }}
      </h1>
    </div>
    <div class="lg:flex lg:mx-9 mt-9 mx-6">
      <div class="lg:w-1/2">
        <img
          class="rounded-lg"
          :src="formation.info_complete"
          :alt="formation.name"
        />
      </div>

      <div class="lg:w-1/2 mt-6">
        <h1
          class="text-blue-500 lg:text-3xl text-xl mt-4 lg:text-left lg:ml-12 font-mono md:mx-auto md:text-center"
        ></h1>

        <p
          class="text-gray-700 lg:text-2xl text-sm font-serif mt-2 lg:ml-12 lg:text-left text-justify"
        >
          {{ formation.description }}
        </p>

        <p
          class="text-gray-700 lg:text-2xl text-sm font-serif mt-4 lg:ml-12 lg:text-left"
        >
          La formation se déroulera sur une durée de
          <span class="text-blue-500 font-mono text-xl"
            >{{ formation.document_url }}
          </span>
          entre les dates de

          <span class="text-blue-500 font-mono text-xl">
            {{ formatDate(formation.date) }} au  {{ formatDate(formation.meet_url) }} </span
          >
        </p>
        <h1
          class="lg:text-2xl text-xl mt-4 lg:text-left lg:ml-12 font-mono md:mx-auto md:text-center"
        >
          Statut: <span class="text-green-500"> {{ formation.status }}</span>
        </h1>
        <h1
          class="lg:text-2xl text-xl mt-4 lg:text-left lg:ml-12 font-mono md:mx-auto md:text-center"
        >
          Les sections de la formations
        </h1>
        <div v-for="(section , index) in sortedSections" :key="index">
          <router-link  :to="'/section/' + section.id">
          <h1
            class="text-xl mt-4 lg:text-left lg:ml-12 font-sans md:mx-auto md:text-center flex text-blue-500 "
          >
         
          {{ index+1 }}-{{ formatDateTime(section.date) }}<br>{{ section.name }}
          </h1>
          </router-link>
         
        </div>
      </div>
    </div>

    <div class="h-9"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Video",
  data() {
    return {
      formations: [],
      currentPage: 1,
      itemsPerPage: 16,
      role_ID: "",
      formationID: "82829206-1984-4f12-8ccc-6beb928979ff",
      sections: [],
    };
  },
  mounted() {
    this.getFormations();
    this.profile();
    //this.filterFormations();
  },
  computed: {
    filteredFormations() {
      // Filtre les formations en fonction du roleID
      return this.formations.filter(
        (formation) =>
          formation.roleID === this.role_ID ||
          this.role_ID === "5cb0663d-c4ee-4a77-8f3d-f6505ea400df"
      );
    },
    
    sortedSections() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.sections
        .slice()
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    },
  },

  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.user = response.data.id;
          this.role_ID = response.data.role_id;
          this.imageUrl = response.data.profile_photo_path;
        }
      } catch (error) {
        this.user = error.data;
      }
    },
    async Section(id) {
      try {
        const response = await axios.get(`/api/sections?formation_id=${id}`);
        if (response.data) {
          this.sections = response.data.data;
          console.log(this.sections);

          // Filtrer les rôles
        }
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    },
    async getFormations() {
      try {
        const response = await axios.get("/api/formations");
        if (response.data) {
          this.formations = response.data.data;
        }
        this.Section(this.formationID);
      } catch (error) {
        console.log(error.data);
      }
    },
     formatDateTime(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('fr-FR', options).format(date);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('fr-FR', options).format(date);
    }
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
