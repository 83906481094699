<template>
  <div class="">
    <div class="relative flex items-center justify-center">
      <img class="w-full mt-6 lg:mt-0" src="../assets/CSEcybersec.png" alt="" />
    </div>
    <div
      v-for="(article, index) in paginatedCards"
      :key="index"
      class="lg:flex lg:mx-16 mt-9 mx-6 border-4 border-gray-400 rounded-lg shadow-xl"
    >
      <div
        class="border-4 border-purple-900 rounded-lg lg:ml-9 my-4 mx-2 lg:mx-0"
      >
        <img
          class="w-96 h-64 mx-auto md:mx-auto"
          :src="article.image_url"
          :alt="article.alt"
        />
      </div>
      <div class="lg:w-2/3">
        <h1
          class="text-blue-500 lg:text-3xl text-xl mt-4 mx-2 lg:mx-0 lg:text-left lg:mr-12 lg:ml-12 font-bold"
        >
          {{ article.name }}
        </h1>
        <div class="flex mt-4">
          <div v-if="article.user.profile_photo_path === null" class="justify-start lg:ml-12 mt-2 mx-2 lg:mx-0">
            <img
              class="object-cover lg:w-20 lg:h-20 w-16 h-16 border-2 border-green-500 rounded-full"
              src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
            />
          </div>
          <div v-else class="justify-start lg:ml-12 mt-2 mx-2 lg:mx-0">
            <img
              class="object-cover lg:w-20 lg:h-20 w-16 h-16 border-2 border-green-500 rounded-full"
              :src="article.user.profile_photo_path"
            />
          </div>
          <p class="text-lg text-gray-800 font-bold mt-6 ml-4">
            {{ article.user.name }}
          </p>
          <p class="text-lg text-gray-800 font-sans mt-6 lg:ml-auto">
            {{
              new Date(article.updated_at).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </p>
        </div>
        <p
          class="text-gray-700 text-lg mt-2 lg:mr-12 lg:ml-12 mx-2 lg:mx-0 lg:text-left mt-4"
        >
          {{ article.description }}
        </p>
        <div
          class="text-sm font-bold mt-2 text-white text-center lg:text-right mr-2 mb-4 lg:mb-0"
        >
          <router-link
            class="bg-purple-900 rounded-lg px-2 py-2 inline-block mb-6"
            :to="'/blogplus/' + article.id"
            >Lire la suite</router-link
          >
        </div>
      </div>
    </div>
   <div class="flex justify-center mt-9 mb-4" v-if="totalPages > 1">
  <nav aria-label="Page navigation example">
    <ul class="flex items-center -space-x-px h-12 text-sm">
      <li v-if="currentPage > 1">
        <a
          @click.prevent="goToPage(currentPage - 1)"
          class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span class="sr-only">Previous</span>
          <svg
            class="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </a>
      </li>
      <li v-for="page in pages" :key="page">
        <a
          @click.prevent="goToPage(page)"
          :class="{
            'z-10 flex items-center justify-center px-3 h-12 leading-tight': true,
            'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
              currentPage === page,
            'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white':
              currentPage !== page,
          }"
        >
          {{ page }}
        </a>
      </li>
      <li v-if="currentPage < totalPages">
        <a
          @click.prevent="goToPage(currentPage + 1)"
          class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span class="sr-only">Next</span>
          <svg
            class="w-2.5 h-2.5 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</div>

    <div class="mt-16 relative text-center mx-auto">
      <p class="lg:text-4xl text-xl text-blue-400 font-mono">
        Restez informés avec CSE TRAININGS
      </p>
      <p class="text-lg text-black font-serif mt-2 lg:mx-52 mx-6">
        Restez informés sur l’actualité de nos différentes activités via notre
        newsletter.
      </p>
      <p class="text-xl text-black font-bold mt-2 lg:mx-52">
        Notre address e-mail:
      </p>
      <div class="mt-2">
        <router-link
          class="bg-purple-900 px-4 py-2 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
          to="#"
          >support@csetrainings.com</router-link
        >
      </div>
      <div class="mt-4">
        <router-link
          class="bg-purple-900 px-4 py-2 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
          to="/auth/register"
          >S'inscrire</router-link
        >
      </div>
    </div>
    <div class="h-9"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Blog",
  data() {
    return {
      blogs: [],
      currentPage: 1,
      itemsPerPage: 5,
      
    };
  },
  mounted() {
  
    this.getBlogs();
  },
  computed: {
    totalPages() {
    return Math.ceil(this.sortedBlogs.length / this.itemsPerPage);
  },
    formattedUpdatedAt() {
      const updatedAt = new Date(this.blogs.updated_at);

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      // Utilisez la méthode toLocaleString pour formater la date
      return updatedAt.toLocaleString("fr-FR", options);
    },
    sortedBlogs() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.blogs
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
     paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.sortedBlogs.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.sortedBlogs.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return this.currentPage * this.itemsPerPage < this.sortedBlogs.length;
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.sortedBlogs.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },
  methods: {
    
    async getBlogs() {
      try {
        const response = await axios.get("/api/blogs");
        if (response.data) {
          this.blogs = response.data.data;
          console.log(this.blogs);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
     nextSlide() {
      if (this.currentIndex + 3 < this.slides.length) {
        this.currentIndex += 1;
        this.updateSlider();
       
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
        this.updateSlider();
       
      }
    },
    updateSlider() {
      const translateValue = -this.currentIndex * (100 / 3) + "%";
      this.$refs.slider.style.transform = "translateX(" + translateValue + ")";
      
    },
    goToPage(page) {
  this.currentPage = page;
  console.log(window.innerHeight);
  window.scrollTo(0, window.innerHeight / 2); // Défilement jusqu'au quart de la page
},

    loadMore() {
      // Ajoutez ici la logique pour charger plus d'éléments depuis votre source de données (définie localement)
      // Assurez-vous d'ajuster cette logique en fonction de votre situation réelle
      try {
        const newCards = this.getMoreCards(); // Remplacez par votre logique pour récupérer plus de cartes localement
        this.cards = this.cards.concat(newCards);
        this.currentPage++;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données supplémentaires",
          error
        );
      }
    },
  },
};
</script>
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
