<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center mt-9 lg:mt-0">
      <h1 class="font-bold lg:text-4xl text-xl text-white">
        CONDITIONS GENERALES D'UTILISATION
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4 mx-4 lg:mx-0">
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les présentes conditions générales d'utilisation (dites « CGU ») ont
        pour objet l'encadrement juridique des modalités de mise à disposition
        du site et des services par CSE TRAININGS et de définir les conditions
        d’accès et d’utilisation des services par « l'Utilisateur ».<br /> Les
        présentes CGU sont accessibles sur le site à la rubrique «CGU».<br /> Toute
        inscription ou utilisation du site implique l'acceptation sans aucune
        réserve ni restriction des présentes CGU par l’utilisateur. Lors de
        l'inscription sur le site via le Formulaire d’inscription, chaque
        utilisateur accepte expressément les présentes CGU en cochant la case
        précédant le texte suivant : « Je reconnais avoir lu et compris les CGU
        et je les accepte ». En cas de non-acceptation des CGU stipulées dans le
        présent contrat, l'Utilisateur se doit de renoncer à l'accès des
        services proposés par le site. www.csetrainings.com se réserve le droit
        de modifier unilatéralement et à tout moment le contenu des présentes
        CGU.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 1 : LES MENTIONS LEGALES
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        L'édition du site www.csetrainings.com est assurée par la Société CSE
        TRAININGS au capital de 0 euros, dont le siège social est situé aux USA.
        Adresse e-mail : support@csetrainings.com Le Directeur de la publication
        est : PATRICK TAHOU L'hébergeur du site www.csetrainings.com est la
        société Digital Ocean, dont le siège social est situé aux USA.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 2 : ACCES AU SITE
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Le site www.csetrainings.com permet à l'Utilisateur un accès gratuit aux
        services suivants :
        <br />Tutorial<br />
        Cours<br />
        Exercices<br />
        Vidéo<br />
        Forum<br />
        Le site est accessible gratuitement en tout lieu à tout Utilisateur
        ayant un accès à Internet. Tous les frais supportés par l'Utilisateur
        pour accéder au service (matériel informatique, logiciels, connexion
        Internet, etc.) sont à sa charge. <br />L’Utilisateur non-membre n'a pas accès
        aux services réservés. Pour cela, il doit s’inscrire en remplissant le
        formulaire. En acceptant de s’inscrire aux services réservés,
        l’Utilisateur membre s’engage à fournir des informations sincères et
        exactes concernant son état civil et ses coordonnées, notamment son
        adresse électronique. Pour accéder aux services, l’Utilisateur doit
        ensuite s'identifier à l'aide de son identifiant et de son mot de passe
        créés lors de son inscription. Tout événement dû à un cas de force
        majeure ayant pour conséquence un dysfonctionnement du site ou serveur
        et sous réserve de toute interruption ou modification en cas de
        maintenance, n'engage pas la responsabilité de www.csetrainings.com.
        Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à
        l’éditeur de toute interruption ou suspension de service, même sans
        préavis. L'Utilisateur a la possibilité de contacter le site par
        messagerie électronique à l’adresse email de l’éditeur communiqué à
        l’ARTICLE 1.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 3 : COLLECTE DES DONNEES
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Le site assure à l'Utilisateur une collecte et un traitement
        d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés. <br />En vertu de la loi
        Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
        dispose d'un droit d'accès, de rectification, de suppression et
        d'opposition de ses données personnelles. L'Utilisateur exerce ce droit:
        <br />
        · par mail à l'adresse email support@csetrainings.com <br />· via un
        formulaire de contact ;
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 4 : PROPRIETE INTELLECTUELLE
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les marques, logos, signes ainsi que tous les contenus du site (textes,
        images, son…) font l'objet d'une protection par le Code de la propriété
        intellectuelle et plus particulièrement par le droit d'auteur.
        L'Utilisateur doit solliciter l'autorisation préalable du site pour
        toute reproduction, publication, copie des différents contenus. Il
        s'engage à une utilisation des contenus du site dans un cadre
        strictement privé, toute utilisation à des fins commerciales et
        publicitaires est strictement interdite. Toute représentation totale ou
        partielle de ce site par quelque procédé que ce soit, sans
        l’autorisation expresse de l’exploitant du site Internet constituerait
        une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de
        la propriété intellectuelle. Il est rappelé conformément à l’article
        L122-5 du Code de propriété intellectuelle que l’Utilisateur qui
        reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa
        source.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 5 : RESPONSABILITE
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les sources des informations diffusées sur le site www.csetrainings.com
        sont réputées fiables mais le site ne garantit pas qu’il soit exempt de
        défauts, d’erreurs ou d’omissions. Les informations communiquées sont
        présentées à titre indicatif et général sans valeur contractuelle.
        Malgré des mises à jour régulières, le site www.csetrainings.com ne peut
        être tenu responsable de la modification des dispositions
        administratives et juridiques survenant après la publication. De même,
        le site ne peut être tenue responsable de l’utilisation et de
        l’interprétation de l’information contenue dans ce site. L'Utilisateur
        s'assure de garder son mot de passe secret. Toute divulgation du mot de
        passe, quelle que soit sa forme, est interdite. Il assume les risques
        liés à l'utilisation de son identifiant et mot de passe. Le site décline
        toute responsabilité. Le site www.csetrainings.com ne peut être tenu
        pour responsable d’éventuels virus qui pourraient infecter l’ordinateur
        ou tout matériel informatique de l’Internaute, suite à une utilisation,
        à l’accès, ou au téléchargement provenant de ce site. La responsabilité
        du site ne peut être engagée en cas de force majeure ou du fait
        imprévisible et insurmontable d'un tiers.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 6 : LIENS HYPERTEXTES
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        Des liens hypertextes peuvent être présents sur le site. L’Utilisateur
        est informé qu’en cliquant sur ces liens, il sortira du site
        www.csetrainings.com. Ce dernier n’a pas de contrôle sur les pages web
        sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
        responsable de leur contenu.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">ARTICLE 7 : COOKIES</h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        L’Utilisateur est informé que lors de ses visites sur le site, un cookie
        peut s’installer automatiquement sur son logiciel de navigation. Les
        cookies sont de petits fichiers stockés temporairement sur le disque dur
        de l’ordinateur de l’Utilisateur par votre navigateur et qui sont
        nécessaires à l’utilisation du site www.csetrainings.com. Les cookies ne
        contiennent pas d’information personnelle et ne peuvent pas être
        utilisés pour identifier quelqu’un. Un cookie contient un identifiant
        unique, généré aléatoirement et donc anonyme. Certains cookies expirent
        à la fin de la visite de l’Utilisateur, d’autres restent. L’information
        contenue dans les cookies est utilisée pour améliorer le site
        www.csetrainings.com. En naviguant sur le site, L’Utilisateur les
        accepte. L’Utilisateur doit toutefois donner son consentement quant à
        l’utilisation de certains cookies. A défaut d’acceptation, l’Utilisateur
        est informé que certaines fonctionnalités ou pages risquent de lui être
        refusées. L’Utilisateur pourra désactiver ces cookies par
        l’intermédiaire des paramètres figurant au sein de son logiciel de
        navigation.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 8 : DROIT APPLICABLE ET JURIDICTION COMPETENTE
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        La législation française s'applique au présent contrat. En cas d'absence
        de résolution amiable d'un litige né entre les parties, les tribunaux
        français seront seuls compétents pour en connaître. Pour toute question
        relative à l’application des présentes CGU, vous pouvez joindre
        l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
      </p>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  