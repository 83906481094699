<template>
  <div class="">
    <div class="relative flex items-center justify-center">
      <img class="mt-6 h-96 w-2/4 lg:mt-0" :src="blog.image_url" alt="" />
    </div>
    <div class="flex lg:mx-32 mx-6 mt-9 rounded-lg">
      <div class="">
        <h1
          class="text-blue-500 lg:text-3xl text-xl mt-4 text-center mx-auto font-mono"
          v-html="blog.name"
        ></h1>

        <div class="lg:flex mt-4 lg:ml-96">
          <div
            v-if="this.imageUrl === null"
            class="justify-start lg:ml-12 mt-2 ml-28"
          >
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full md:ml-56 lg:ml-0"
              src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
            />
          </div>
          <div v-else class="justify-start lg:ml-12 mt-2 ml-28">
            <img
              class="object-cover w-20 h-20 border-2 border-green-500 rounded-full md:ml-56 lg:ml-0"
              :src="this.imageUrl"
            />
          </div>
          <p class="text-lg text-gray-800 font-bold mt-6 lg:ml-4 text-center">
            {{ userName }}
          </p>
          <p class="text-lg text-gray-800 font-sans mt-6 lg:ml-24 text-center">
            {{
              new Date(blog.updated_at).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </p>
        </div>
        <p
          class="text-gray-700 text-lg mt-2 lg:mr-12 mx-4 lg:mx-0 lg:ml-12 text-left mt-4"
          v-html="cleanedContent"
        ></p>
      </div>
    </div>

    <div class="mx-6 lg:mx-64 mt-4">
      <div class="mb-4">
        <h1 class="text-blue-500 lg:text-3xl text-lg mt-4 text-center">
          Souhaitez-vous posez des questions ?
        </h1>
        <form
          v-if="this.user === undefined || this.user === ''"
          @submit.prevent="!passwordErrorMessage && AnswerBlog()"
          class="mt-6 md:mx-24"
        >
          <div class="mb-4">
            <div class="mb-6">
              <input
                v-model="formData.name"
                required
                class="shadow appearance-none border mt-4 rounded md:w-full lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="name"
                placeholder="Nom"
              />
            </div>
            <div class="mb-6">
              <input
                v-model="formData.email"
                @input="validatePassword"
                required
                class="shadow appearance-none border mt-4 rounded md:w-full lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="email"
                placeholder="E-mail"
              />
              
            </div>
            <div class="text-red-500" v-if="passwordErrorMessage">{{ passwordErrorMessage }}</div>
            <textarea
              v-model="addform.content"
              required
              class="shadow appearance-none border rounded md:w-full lg:w-full w-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="question"
              name="question"
              placeholder="Message"
              rows="4"
            ></textarea>
          </div>

          <div class="lg:flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 w-lg:w-full w-80 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              
            >
              <span v-if="isLoading">
                <spiner />
              </span>
              <span v-else> Envoyer</span>
            </button>
          </div>
        </form>
        <div
          v-else
          class="rounded-full p-1 box-border border border-orange-500 md:mx-auto mt-4 bg-white overflow-hidden ring-red-300 focus:ring-4 lg:w-full w-80 mt-2 flex items-center py-1"
        >
          <input
            type="text"
            class="rounded-full px-4 focus:outline-none w-full"
            v-model="addform.content"
            required
            placeholder="Votre commentaire ......."
          />
          <button
            @click="AnswerBlog()"
            class="text-sm bg-blue-600 py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
          >
            <span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> Envoyer</span>
          </button>
        </div>
      </div>

      <h2 class="font-semibold mt-6 lg:text-2xl text-xl lg:text-left">
        Commentaires
      </h2>

      <!-- Comment 1 -->
      <div
        class="flex items-start mt-6"
        v-for="(reponse, index) in commentaire"
        :key="index"
      >
        <img
          src="https://via.placeholder.com/40"
          alt="User Avatar"
          class="w-10 h-10 rounded-full mr-4"
        />
        <div>
          <p class="text-gray-600 text-sm">{{ reponse.content }}</p>
          <p class="text-gray-600 text-sm">
            {{
              new Date(reponse.updated_at).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="h-9"></div>
  </div>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "BlogDetail",
  components: {
    spiner,
  },
  data() {
    return {
      addform: {
        //user_id: "",
        blogs_id: "",
        content: "",
      },
      formData: {
        name: "",
        email: "",
        message: "",
        question: "",
      },
      blog: [],
      commentaire: [],
      blogId: "",
      user: "",
      name: "",
      email: "",
      isLoading: false,
      titre: "",
      userName: "",
      imageUrl: "",
       passwordErrorMessage:"",
       
    };
  },
  
 computed: {
    cleanedContent() {
        return this.blog.content ? this.blog.content.replace(/<\/?(ul|ol)[^>]*>/g, '') : this.blog.content;
    }
},

  mounted() {
    this.blogId = this.$route.params.id;
    this.profile();
    this.getBlog();
    this.getCommentaire();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.email = response.data.email;
          this.user = response.data.id;
          console.log(response.data);
        }
      } catch (error) {
        this.user = error.data;
        console.log(this.user);
      }
    },
    async getBlog() {
      try {
        const response = await axios.get(`/api/blogs/${this.$route.params.id}`);
        if (response.data) {
          this.blog = response.data.data;
          this.titre = response.data.data.name;
          this.userName = response.data.data.user.name;
          this.imageUrl = response.data.data.user.profile_photo_path;
          console.log(this.imageUrl);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getCommentaire() {
      try {
        const response = await axios.get(
          `/api/commentaires?blogs_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.commentaire = response.data.data;
          console.log(this.commentaire);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
  validatePassword() {
  // eslint-disable-next-line no-useless-escape
 const emailPattern = /\S+@\S+\.\S+/;

  if (!emailPattern.test(this.formData.email)) {
    this.passwordErrorMessage = "L'email est invalide.";
  } else {
    this.passwordErrorMessage = '';
  }
},
   
    async AnswerBlog() {
      try {
        this.isLoading = true;
        //this.addform.user_id = this.user;
        this.addform.blogs_id = this.$route.params.id;
        const response = await axios.post("/api/commentaires", this.addform);
        if (response.status == 201) {
          this.getCommentaire();
          this.submitForm();
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre chapitre à été enregistrer avec succés",
            timeout: 5000,
          }).show();
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },

    submitForm() {
      // Ajouter une vérification avant d'envoyer la requête axios
      if ( this.formData.name !== "") {
        this.formData.message = this.addform.content;
        this.formData.question = this.titre;
      }
      if (this.addform.content !== "" && this.formData.name === "") {
        this.formData.message = this.addform.content;
        this.formData.name = this.name;
        this.formData.email = this.email;
        this.formData.question = this.titre;
      }
      axios
        .post("/api/sendeblog", this.formData)
        .then((response) => {
          this.addform = {};
          this.formData = {}; // Réinitialiser formData après l'envoi du message
          this.isLoading = false; // Définir isLoading à false après la réponse du serveur
          console.log(response.data);
        })
        .catch((error) => {
          this.isLoading = false; // Définir isLoading à false en cas d'erreur
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
