<template>
  <div class="">
<div class="relative w-full mt-9 lg:mt-0  hidden md:block" style=" height: 1900px; background-color: #015790;">
      
     

      <div
        class=" absolute lg:top-72 md:top-44 text-white p-4 -top-1 lg:flex md:flex lg:mx-24 md:mx-4 lg:space-x-9 md:space-x-4 "
      >
        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-white text-black rounded-2xl "
        >
          <img class="hidden md:block lg:block -mt-44" src="../assets/logoforme.png" alt="" />
          <h1 class="lg:text-4xl text-blue-500 text-xl text-center font-mono">
            Pourquoi ?
          </h1>
          <p class="mt-4 text-lg mx-12 mb-6 font-sans ">
            La carence critique de professionnels, souvent désignée sous le
            terme de "déficit de compétences en cybersécurité", constitue un
            défi majeur pour l'industrie. Le CSET vise à atténuer cette
            situation en cultivant une nouvelle génération d'experts en
            cybersécurité, tout en offrant aux professionnels des technologies
            de l'information l'opportunité d'élargir leurs horizons
            professionnels. En proposant une formation pratique de pointe en
            cybersécurité et en réseaux, accompagnée de tutoriels de haute
            qualité, le CSET aspire à accroître l'employabilité de jeunes
            diplômés et non, de demandeurs d'emploi, ainsi que de toute personne
            intéressée par une reconversion professionnelle dans ce secteur. De
            plus, le projet s'engage résolument en faveur de la diversité des
            genres et de l'inclusion, en incitant particulièrement les femmes à
            explorer les carrières dans la cybersécurité. À travers une approche
            pédagogique innovante, le CSET ambitionne également de démystifier
            le domaine de la cybersécurité et de rendre ses aspects techniques
            accessibles à tous, quel que soit leur niveau de compétence initial.
            Cette initiative vise non seulement à enrichir le réservoir de
            talents en cybersécurité mais aussi à sensibiliser à l'importance
            cruciale de la sécurité numérique dans notre société connectée.
          </p>
        </div>

        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-blue-500 text-white rounded-2xl "
        >
          <h1 class="lg:text-4xl text-xl text-center font-mono mt-16">
            Description
          </h1>
          <p class="mt-24 text-lg mx-12 mb-6 font-sans">
            CSE TRAININGS se positionne comme votre clé d'accès privilégiée pour
            conquérir les arcanes des réseaux et de la cybersécurité. Nous
            sommes fiers d'être le premier et le seul hub offrant un
            accompagnement complet, entièrement gratuit, en mentoring et
            coaching pratique dans ces domaines critiques. Chez nous,
            l'apprentissage transcende la théorie pour plonger dans des
            applications concrètes, inspirées de situations réelles. Cette
            approche est magnifiée par l'expertise et les insights de nos
            mentors expérimentés, qui partagent leur savoir avec passion et
            engagement. Au cœur de notre communauté CSE, l'échange et la
            collaboration foisonnent. Les discussions vives, la résolution
            d'exercices, l'analyse d'études de cas tirées du réel et, surtout,
            l'accompagnement personnalisé de nos experts, sont autant de pierres
            angulaires qui vous propulsent non seulement vers l'apprentissage,
            mais vers l'excellence. Notre plateforme s'adapte à chaque parcours
            individuel, s'enrichissant de la diversité de ses membres et de
            leurs contributions, pour servir une mosaïque de passions, de
            curiosités et de niveaux d'expertise. Que votre but soit l'obtention
            d'une certification, une réorientation professionnelle, ou
            simplement l'épanouissement personnel à travers l'IT, CSE TRAININGS
            est votre allié. Nous nous engageons à soutenir vos ambitions avec
            conviction et dévouement. Le CSET se définit comme une communauté
            ouverte, animée par la conviction que la sécurité numérique se
            fortifie à travers l'éducation, le partage des outils et la
            collaboration active. Ensemble, construisons un avenir numérique
            plus sûr pour tous.
          </p>
        </div>
      </div>

      <div
        class="absolute -bottom-32  lg:-bottom-72 mx-6 text-black p-4 lg:mx-64 font-sans text-sm lg:text-4xl text-center"
      >
        <img class="w-full" src="../assets/Patrickquisuije.png" alt="" />
      </div>
    </div>


<div class="relative w-full mt-9 lg:mt-0 block md:hidden" style="min-height: 3300px; background-color: #015790;">
  <div class="text-white p-4 lg:flex md:flex lg:mx-24 md:mx-4 lg:space-x-9 md:space-x-4">
    <div class="lg:text-4xl text-lg text-left lg:w-1/2 bg-white text-black rounded-2xl">
      <img class="hidden md:block lg:block -mt-44" src="../assets/logoforme.png" alt="" />
      <h1 class="lg:text-4xl text-blue-500 text-xl text-center font-mono">Pourquoi ?</h1>
      <p class="mt-4 text-lg mx-12 mb-6 font-sans">
        La carence critique de professionnels, souvent désignée sous le terme de "déficit de compétences en cybersécurité", constitue un défi majeur pour l'industrie. Le CSET vise à atténuer cette situation en cultivant une nouvelle génération d'experts en cybersécurité, tout en offrant aux professionnels des technologies de l'information l'opportunité d'élargir leurs horizons professionnels. En proposant une formation pratique de pointe en cybersécurité et en réseaux, accompagnée de tutoriels de haute qualité, le CSET aspire à accroître l'employabilité de jeunes diplômés et non, de demandeurs d'emploi, ainsi que de toute personne intéressée par une reconversion professionnelle dans ce secteur. De plus, le projet s'engage résolument en faveur de la diversité des genres et de l'inclusion, en incitant particulièrement les femmes à explorer les carrières dans la cybersécurité. À travers une approche pédagogique innovante, le CSET ambitionne également de démystifier le domaine de la cybersécurité et de rendre ses aspects techniques accessibles à tous, quel que soit leur niveau de compétence initial. Cette initiative vise non seulement à enrichir le réservoir de talents en cybersécurité mais aussi à sensibiliser à l'importance cruciale de la sécurité numérique dans notre société connectée.
      </p>
    </div>

    <div class="lg:text-4xl text-lg text-left lg:w-1/2 bg-blue-500 text-white rounded-2xl">
      <h1 class="lg:text-4xl text-xl text-center font-mono mt-16">Description</h1>
      <p class="mt-24 text-lg mx-12 mb-6 font-sans">
        CSE TRAININGS se positionne comme votre clé d'accès privilégiée pour conquérir les arcanes des réseaux et de la cybersécurité. Nous sommes fiers d'être le premier et le seul hub offrant un accompagnement complet, entièrement gratuit, en mentoring et coaching pratique dans ces domaines critiques. Chez nous, l'apprentissage transcende la théorie pour plonger dans des applications concrètes, inspirées de situations réelles. Cette approche est magnifiée par l'expertise et les insights de nos mentors expérimentés, qui partagent leur savoir avec passion et engagement. Au cœur de notre communauté CSE, l'échange et la collaboration foisonnent. Les discussions vives, la résolution d'exercices, l'analyse d'études de cas tirées du réel et, surtout, l'accompagnement personnalisé de nos experts, sont autant de pierres angulaires qui vous propulsent non seulement vers l'apprentissage, mais vers l'excellence. Notre plateforme s'adapte à chaque parcours individuel, s'enrichissant de la diversité de ses membres et de leurs contributions, pour servir une mosaïque de passions, de curiosités et de niveaux d'expertise. Que votre but soit l'obtention d'une certification, une réorientation professionnelle, ou simplement l'épanouissement personnel à travers l'IT, CSE TRAININGS est votre allié. Nous nous engageons à soutenir vos ambitions avec conviction et dévouement. Le CSET se définit comme une communauté ouverte, animée par la conviction que la sécurité numérique se fortifie à travers l'éducation, le partage des outils et la collaboration active. Ensemble, construisons un avenir numérique plus sûr pour tous.
      </p>
    </div>
  </div>

  <div class="   mx-6 text-black  font-sans text-sm  text-center ">
    <img class="w-full " src="../assets/Patrickquisuije.png" alt="" />
  </div>
</div>



    <div class="bg-purple-800">
      <div class="lg:mx-24 lg:flex mx-4">
        <div class="h-24 block lg:hidden"></div>
        <div class="lg:w-1/2 lg:mt-44 ">
          <div class="justify-start ">
            <h1
              class="lg:text-2xl text-white text-xl text-center font-mono border border-white w-64 h-12 rounded-2xl"
            >
              Points forts
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Data Center :</p>
                <p class="ml-4">Expert en migration et conception.</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Cloud :</p>
                <p class="ml-4">
                  Spécialiste en migration et conception d’une architecture
                  sécurisée.
                </p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Cybersécurité - Blue Team :</p>
                <p class="ml-4">
                  Expert en défense proactive contre les cybermenaces.
                </p>
              </div>
            </div>
          </div>
          <div class="justify-start">
            <h1
              class="lg:text-2xl text-white text-xl mt-9 text-center font-mono border border-white w-72 h-12 rounded-2xl"
            >
              Certifications
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-14 h-14 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">
                  De CompTIA à Cisco, en passant par Juniper, Fortinet, Patrick
                  a acquis un large éventail de certifications qui attestent de
                  son engagement envers l'excellence dans son domaine.
                </p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-32 h-32 text-white mt-4 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">
                  Ses qualifications incluent des certifications prestigieuses
                  comme CompTIA Server+, CompTIA Security+, CompTIA CySA+,
                  JNCIA-Junos, CCNA R&S, CCNP R&S, CCNA Security, CCNP Security,
                  CCIE "written", Fortinet NSE4, WCNA (Wireshark Certified
                  Network Analyst), CEH (Certified Ethical Hacker), PCAP
                  (Certified Associate Python Programmer), ISO/IEC 27001
                  LeadAuditor, Microsoft MCSA, entre autres.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:w-1/2 lg:mt-44">
          <div class="justify-start">
            <h1
              class="lg:text-2xl text-white text-xl text-center font-mono border border-white w-64 h-12 rounded-2xl"
            >
              Passions
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-9 h-9 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Musique :</p>
                <p class="ml-4">Patrick a été également un DJ et ingénieur du son chevronné, avec 15 ans d'expérience professionnelle.</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-7 h-7 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Arts martiaux :</p>
                <p class="ml-4">
                  Il a été deux fois champion d'Italie de taekwondo dans sa catégorie.
                </p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8 h-8 text-white mt-4 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">Cybersécurité Red Team :</p>
                <p class="ml-4">
                  Une passion pour l'offensive en cybersécurité, complémentaire à son expertise en défense.
                </p>
              </div>
            </div>
            <img class="" src="../assets/0000000.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";

export default {
  name: "App",
  components: {
    ModalComponent,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  mounted() {
    this.startSlider();
  },
  methods: {
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    openModal() {
      this.isModalOpen = true; // Ouvrir le modal
    },
    closeModal() {
      this.isModalOpen = false; // Fermer le modal
    },
  },
};
</script>
<style scoped>
.custom-background2 {
  background-image: url("~@/assets/degrade.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
